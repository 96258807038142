export const COUNTRIES_LIST = [
    'S. AM',
    'CO',
    'LEV',
    'AU',
    'DK',
    'DE',
    'PE',
    'CZ',
    'NO',
    'UA',
    'RO',
    'HU',
    'NG',
    'IT',
    'FI',
    'PT',
    'LAT',
    'SE',
    'CA',
    'GB',
    'TH',
    'KR',
    'IN',
    'IL',
    'BD',
    'PK',
    'BR',
    'TW',
    'TR',
    'PH',
    'ID',
    'NL',
    'PL',
    'GULF',
    'MX',
    'LK',
    'JP',
    'IS',
    'FR',
    'EG',
    'MY',
    'ES',
    'GH',
    'VN',
    'KE',
    'MGH',
    'ZA',
    'CH',
    'HK',
    'US',
    'GR',
    'BE',
    'SG',
    'SUR',
];

export const COUNTRIES_BY_REGION: Record<string, string[]> = {
    'North America': ['US', 'CA'],
    'Continental Europe': [
        'DE',
        'FR',
        'IT',
        'NL',
        'PL',
        'CH',
        'BE',
        'CZ',
        'GR',
        'HU',
        'RO',
    ],
    Latin: ['PE', 'MX', 'BR', 'ES', 'CO', 'PT'],
    UK: ['GB'],
    Asia: [
        'IN',
        'ID',
        'PH',
        'TW',
        'MY',
        'HK',
        'GB',
        'KR',
        'BD',
        'JP',
        'LK',
        'SG',
        'TH',
    ],
    'AU & NZ': ['AU', 'NZ'],
    Nordics: ['SE', 'DK', 'NO', 'FI', 'IS'],
    Africa: ['NG', 'ZA', 'SE', 'KE'],
    'Middle East': ['GULF', 'TR', 'EG', 'IL', 'LEV'],
    Other: ['UA'],
};

export const COUNTRY_OPTIONS = COUNTRIES_LIST.map(countryCode => ({
    countryCode,
}));

export const COUNTRIES_CELL_LIMIT = 10;

export const ALL = 'all';
export const FEATURED = 'featured';
export const NOT_FEATURED = 'notFeatured';

export const FEATURING_GROUP_ITEMS = [
    {
        label: 'All',
        value: ALL,
    },
    {
        label: 'Featured In',
        value: FEATURED,
    },
    {
        label: 'Not Featured In',
        value: NOT_FEATURED,
    },
];

export const TOP_MARKET = 'topMarket';
export const ALPHABETICAL = 'alphabetical';
export const SONG_POSITION = 'songPosition';

export const MARKETS_SORTING_GROUP_ITEMS = [
    {
        label: 'Top Market',
        value: TOP_MARKET,
    },
    {
        label: 'Alphabetical',
        value: ALPHABETICAL,
    },
    {
        label: 'Song Position',
        value: SONG_POSITION,
    },
];

export const FEATURING_DEFAULT_VALUE = ALL;
export const MARKETS_SORTING_DEFAULT_VALUE = TOP_MARKET;

export const TABLE_PAGINATION_PAGE_SIZE = 200;
export const LOADING_ROWS_COUNT = 20;
export const COUNTRY_LOADING_INDICATOR_COUNT = 3;
