import React from 'react';
import { formatMessage } from '@theorchard/suite-frontend';
import { Illustration } from '@theorchard/suite-icons';
import { SharedErrorProps } from 'src/components/error/error';
import InfoMessage from 'src/components/infoMessageV2/infoMessageV2';

const NoResults: React.FC<SharedErrorProps> = ({ size = 100 }) => {
    const title = formatMessage('error.noResultsFiltering.title');
    const message = formatMessage('error.noResultsFiltering.message');
    const Icon = () => <Illustration name="noResults" size={size} />;

    return <InfoMessage title={title} message={message} Icon={Icon} />;
};

export default NoResults;
