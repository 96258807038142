import React, { FC } from 'react';
import {
    HelpTooltip,
    Modal,
    Table,
    Tooltip,
} from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { CountryFlag, GlyphIcon } from '@theorchard/suite-icons';
import { uniqueId } from 'lodash';
import { Entity } from 'src/apollo/selectors/types';
import { getCountryName } from 'src/constants/countries';
import { buildYoutubeLink, YoutubeTypeLinks } from 'src/utils/charts';

export const CLASSNAME = 'YoutubePolicyModal';
const CLASS_CONTAINER = `${CLASSNAME}-container`;
const CLASS_TABLE = `${CLASSNAME}-table`;
const CLASS_LABEL = `${CLASSNAME}-label`;
const CLASS_COUNTRIES = `${CLASSNAME}-countries`;
const CLASS_POLICIES = `${CLASSNAME}-policies`;
const CLASS_COUNTRY = `${CLASSNAME}-country`;
const CLASS_TOOLTIP = `${CLASSNAME}-tooltip`;
const CLASS_METADATA = `${CLASSNAME}-metadata`;
const CLASS_LINK = `${CLASSNAME}-link`;

const TERM_CLAIM_POLICY = 'video.claimPolicy';
export const TERM_MONETIZE = 'video.monetizePolicy';
export const TERM_TRACK = 'video.trackPolicy';
export const TERM_BLOCK = 'video.blockPolicy';
const TERM_MIXED = 'video.mixedPolicy';
const TERM_VIDEO = 'video.video';
const TERM_CHANNEL = 'channel.channel';
const TERM_MONETIZE_HELP = 'video.help.monetizePolicy';
const TERM_TRACK_HELP = 'video.help.trackPolicy';
const TERM_BLOCK_HELP = 'video.help.blockPolicy';

export const COUNTRY_COUNT = 249;

export interface Props {
    isOpen: boolean;
    hideModal: () => void;
    monetize: string[];
    track: string[];
    block: string[];
    video: Entity;
    channel: Entity;
}

export const YoutubePolicyContent: FC<Props> = ({
    monetize,
    track,
    block,
    video,
    channel,
}) => {
    const claimPolicy =
        /* eslint-disable no-nested-ternary */
        monetize.length === COUNTRY_COUNT
            ? TERM_MONETIZE
            : track.length === COUNTRY_COUNT
            ? TERM_TRACK
            : block.length === COUNTRY_COUNT
            ? TERM_BLOCK
            : TERM_MIXED;
    /* eslint-enable no-nested-ternary */

    const renderCountries = (policyName: string, policy: string[]) => {
        const countries = policy.map(countryCode => {
            const tooltipMessage = (
                <div className={CLASS_TOOLTIP}>
                    <CountryFlag countryCode={countryCode} />
                    <span>{getCountryName(countryCode)}</span>
                </div>
            );
            return (
                <Tooltip
                    id={`${CLASS_COUNTRY}_${countryCode}_${uniqueId()}`}
                    message={tooltipMessage}
                    key={countryCode}
                >
                    <div key={countryCode} className={CLASS_COUNTRY}>
                        {countryCode}
                    </div>
                </Tooltip>
            );
        });

        return (
            policy.length > 0 && (
                <>
                    <h4 className="mt-2 ml-1">{formatMessage(policyName)}</h4>
                    <div className={CLASS_COUNTRIES} data-testid={policyName}>
                        {countries}
                    </div>
                </>
            )
        );
    };

    return (
        <div data-testid={CLASSNAME} className={CLASS_CONTAINER}>
            <div>
                <Table borderless className={CLASS_TABLE}>
                    <tbody>
                        <tr>
                            <td className={CLASS_LABEL}>
                                {formatMessage(TERM_CLAIM_POLICY)}:
                            </td>
                            <td>{formatMessage(claimPolicy)}</td>
                        </tr>
                        <tr>
                            <td className={CLASS_LABEL}>
                                {formatMessage(TERM_MONETIZE)}:
                                <HelpTooltip
                                    id="monetize-help"
                                    message={formatMessage(TERM_MONETIZE_HELP)}
                                />
                            </td>
                            <td>{`${monetize.length}/${COUNTRY_COUNT}`}</td>
                        </tr>
                        <tr>
                            <td className={CLASS_LABEL}>
                                {formatMessage(TERM_TRACK)}:
                                <HelpTooltip
                                    id="track-help"
                                    message={formatMessage(TERM_TRACK_HELP)}
                                />
                            </td>
                            <td>{`${track.length}/${COUNTRY_COUNT}`}</td>
                        </tr>
                        <tr>
                            <td className={CLASS_LABEL}>
                                {formatMessage(TERM_BLOCK)}:
                                <HelpTooltip
                                    id="block-help"
                                    message={formatMessage(TERM_BLOCK_HELP)}
                                />
                            </td>
                            <td>{`${block.length}/${COUNTRY_COUNT}`}</td>
                        </tr>
                    </tbody>
                </Table>
                <div className={CLASS_METADATA}>
                    <div className={CLASS_LABEL}>
                        {formatMessage(TERM_CHANNEL)}:
                    </div>
                    <a
                        className={CLASS_LINK}
                        href={buildYoutubeLink({
                            channel: channel.id,
                            type: YoutubeTypeLinks.channel,
                        })}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <span className="mr-1">{channel.name}</span>
                        <GlyphIcon name="externalLink" size={12} />
                    </a>
                    <div className={CLASS_LABEL}>
                        {formatMessage(TERM_VIDEO)}:
                    </div>
                    <a
                        className={CLASS_LINK}
                        href={buildYoutubeLink({
                            videoId: video.id,
                            type: YoutubeTypeLinks.video,
                        })}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <span className="mr-1">{video.name}</span>
                        <GlyphIcon name="externalLink" size={12} />
                    </a>
                </div>
            </div>
            <div className={CLASS_POLICIES}>
                {renderCountries(TERM_MONETIZE, monetize)}
                {renderCountries(TERM_TRACK, track)}
                {renderCountries(TERM_BLOCK, block)}
            </div>
        </div>
    );
};

const YoutubePolicyModal: FC<Props> = props => (
    <Modal
        isOpen={props.isOpen}
        className={CLASSNAME}
        zIndex={100}
        onRequestClose={props.hideModal}
    >
        <YoutubePolicyContent {...props} />
    </Modal>
);

export default YoutubePolicyModal;
