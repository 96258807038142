import { PAGE_ARTISTS, PAGE_SONGS } from 'src/constants/page';

export const SEARCH_RESULT_LIMIT = 50;
export const SEARCH_DEBOUNCE_INTERVAL = 500;

export const PARAM_TERM = 'term';
export const PARAM_CATALOG_ONLY = 'catalog';
export const TERM_SONG = 'catalog.songName';
export const TERM_ARTIST = 'catalog.artistName';
export const TERM_PRODUCT = 'catalog.productName';
export const TERM_YOUTUBE_CHANNEL = 'channel.youtubeChannel.one';

export const PARAM_EXACT_PHRASE = 'exact';

export const PARAM_QUERY_TYPE = 'queryType';

export const LIMIT_OPTIONS = [25, 200, 500];
export const PAGINATED_LIMIT_OPTIONS = [25, 50, 100, 200, 500];
export const DEFAULT_LIMIT_SPOTIFY = 20;

export const TABLE_RESULTS_COMMON_CLASSNAME = 'TableResults';

export const LOADING_INDICATOR_RIGHT_ALIGNMENT_CLASSNAME =
    'LoaderIndicator--alignRight';

export const PAGES_WITH_BRANDS_FILTER = [PAGE_SONGS, PAGE_ARTISTS];
