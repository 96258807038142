import React, { FC } from 'react';
import { formatMessage } from '@theorchard/suite-frontend';
import { Illustration } from '@theorchard/suite-icons';

export type MessageType = 'error' | 'noData';

type Size = 100 | 200;

export interface Props {
    message?: string;
    title?: string;
    type?: MessageType;
    Icon?: React.ComponentType;
    size?: Size;
    Footer?: React.ComponentType;
}

export const CLASSNAME = 'InfoMessageV2';

const getData = (type?: MessageType, size: Size = 100) => {
    switch (type) {
        case 'error':
            return {
                title: formatMessage('error.network.message'),
                message: formatMessage('error.network.subtext'),
                Icon: () => <Illustration name="dataError" size={size} />,
            };
        case 'noData':
            return {
                title: formatMessage('error.noData.message'),
                message: formatMessage('error.noData.subtext'),
                Icon: () => <Illustration name="emptyState" size={size} />,
            };
        default:
            return { title: null, message: null };
    }
};

const InfoMessageV2: FC<Props> = ({
    message,
    type,
    title,
    Icon,
    Footer,
    size = 100,
}) => {
    const data = getData(type, size);

    const infoTitle = title || data.title;
    const infoMessage = message || data.message;
    const InfoIcon = Icon || data.Icon;

    return (
        <div className={CLASSNAME}>
            {InfoIcon && <InfoIcon />}
            <div className={`${CLASSNAME}-title`}>{infoTitle}</div>
            <div className={`${CLASSNAME}-message`}>{infoMessage}</div>
            {Footer && <Footer />}
        </div>
    );
};

export default InfoMessageV2;
