import React from 'react';
import { Button } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { Illustration } from '@theorchard/suite-icons';
import {
    CLASSNAME as SEARCH_ERROR_CLASSNAME,
    SharedErrorProps,
} from 'src/components/error/error';
import InfoMessage from 'src/components/infoMessageV2/infoMessageV2';

const FailedToLoad: React.FC<SharedErrorProps> = ({ size = 100 }) => {
    const title = formatMessage('error.fatal.title');
    const message = formatMessage('error.dataFailed.message');
    const Icon = () => <Illustration name="dataError" size={size} />;

    const Footer = () => {
        return (
            <Button
                variant="secondary"
                onClick={() => window.location.reload()}
                className={`${SEARCH_ERROR_CLASSNAME}__button`}
                size="lg"
            >
                {formatMessage('error.errorPage.refresh')}
            </Button>
        );
    };

    return (
        <InfoMessage
            type="error"
            title={title}
            message={message}
            Icon={Icon}
            Footer={Footer}
        />
    );
};

export default FailedToLoad;
