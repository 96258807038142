import React from 'react';
import { getCountryName, GridTable } from '@theorchard/suite-components';
import { get } from 'lodash';
import CountryCard from 'src/pages/newReleases/components/countryCard';
import { COUNTRIES_CELL_LIMIT } from 'src/pages/newReleases/constants';

export const CLASSNAME = 'NmfCountriesCell';

GridTable.defineColumnTemplate('nmfCountries', {
    Cell: ({ data: { countries }, column: { definition } }) => {
        const selectedCountryCode =
            get(definition, 'data-selectedCountry') ?? '';
        const selectedCountry = countries.find(
            ({ countryCode }) => countryCode === selectedCountryCode
        ) ?? { countryCode: selectedCountryCode, position: null };
        const countriesToShow = selectedCountryCode
            ? [selectedCountry]
            : countries.slice(0, COUNTRIES_CELL_LIMIT);

        return (
            <div className={CLASSNAME}>
                {countriesToShow.map(({ countryCode, position }) => {
                    return (
                        <CountryCard
                            key={countryCode}
                            countryCode={countryCode}
                            tooltipMessage={getCountryName(countryCode)}
                            position={position}
                            view="table"
                        />
                    );
                })}
            </div>
        );
    },
});
