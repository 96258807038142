import { Entity } from 'src/apollo/selectors/types';

export const ENTITY_TYPES = {
    ViDEO: 'video',
    SONG: 'song',
};

export const TABS = [
    { id: ENTITY_TYPES.ViDEO, term: 'video.videos' },
    { id: ENTITY_TYPES.SONG, term: 'catalog.songs' },
];

export enum RelatedEntityTypes {
    SoundRecording,
    Video,
}

export const VIDEO_TERM = 'video.video';

export interface RelatedEntityProps {
    entity: Entity;
    value: number;
    type: RelatedEntityTypes;
    channelOwner?: string;
    videoContentType?: string;
}
