import React from 'react';
import { GridTable, HelpTooltip } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { GlyphIcon } from '@theorchard/suite-icons';
import EmptyCell from './emptyCell';

GridTable.defineColumnTemplate('myCatalog', {
    align: 'left',
    HeaderText: () => null,
    maxWidth: 'max-content',
    sortable: false,
    Cell: ({ data: { isPartOfCatalog } }) => {
        if (isPartOfCatalog)
            return (
                <HelpTooltip
                    id="in-my-catalog-tooltip"
                    message={formatMessage('comparison.inMyCatalogHint')}
                >
                    <div className="MyCatalogCell" data-testid="MyCatalogCell">
                        <GlyphIcon name="inCatalog" size={24} />
                    </div>
                </HelpTooltip>
            );
        return <EmptyCell />;
    },
});
