// These feature flags are persistent for our app, and we don't need to remove them in near time.
export const INSIGHTS_DISTRIBUTOR_TOGGLE = 'insights_distributor_toggle'; // DO NOT REMOVE
export const INSIGHTS_SHOW_SME_DATA = 'show_sme_data'; // DO NOT REMOVE
export const INSIGHTS_MINIPLAYER_PLAYLISTS = 'insights_miniplayer_playlists'; // DO NOT REMOVE
export const INSIGHTS_EMPLOYEE_OVERRIDE = 'insights_employee_override'; // DO NOT REMOVE
export const INSIGHTS_SME_EMPLOYEE = 'insights_sme_employee'; // DO NOT REMOVE
export const INSIGHTS_AWAL_VIDEOS = 'insights_awal_videos'; // DO NOT REMOVE
export const INSIGHTS_FIN_LABEL_MODE = 'insights_fin_label_rca'; // DO NOT REMOVE
export const INSIGHTS_PROFIT_CENTER = 'insights_profit_center'; // DO NOT REMOVE

export const INSIGHTS_NEW_BRANDS = 'insights_new_brands';
export const INSIGHTS_SONG_TIKTOK_ENHANCED_DATA =
    'insights_song_tiktok_enhanced_data';
export const INSIGHTS_POT_ZOOMABLE = 'insights_pot_zoomable';
export const INSIGHTS_YOUTUBE_POT_FOOTER = 'insights_youtube_pot_footer';
export const INSIGHTS_GAINERS_EMPLOYEE_FILTERS =
    'insights_gainers_employee_filters';
export const INSIGHTS_GAINERS_LABEL_FILTER = 'insights_gainers_label_filter';
export const INSIGHTS_SOURCES_TABLE_POPUP_V2 =
    'insights_sources_table_popup_v2';
export const INSIGHTS_PLAYLISTS_STORE_GRAPHS =
    'insights_playlists_store_graphs';
export const INSIGHTS_SONG_COMPARISON = 'insights_song_comparison';
export const INSIGHTS_VIDEO_COMPARISON = 'insights_video_comparison';
export const INSIGHTS_UGC_STATUS = 'insights_ugc_status';
export const INSIGHTS_UGC_PERCENTAGE = 'insights_ugc_percentage';
export const INSIGHTS_VIDEO_CATALOG_ARTIST = 'insights_video_catalog_artist';
export const INSIGHTS_ACCOUNT_YOUTUBE_STREAMS =
    'insights_account_youtube_streams';
export const INSIGHTS_TIKTOK_TREND_SCORE_COLUMN =
    'insights_tiktok_trend_score_column';
export const INSIGHTS_ARTIST_PLAYLIST_SONGFILTER =
    'insights_artist_playlist_songfilter';
export const INSIGHTS_TIKTOK_SONGS_PAGE = 'insights_tiktok_songs_page';
export const INSIGHTS_SEARCH_V2_BRAND_FILTER =
    'insights_search_v2_brand_filter';
export const INSIGHTS_SOCIAL_LINKS_EDITING = 'insights_social_links_editing';
export const INSIGHTS_PRODUCT_PAGE_PLAYLISTS_TAB =
    'insights_product_page_playlists_tab';
export const INSIGHTS_NAVBAR_V2 = 'insights_navbar_v2';
export const INSIGHTS_PREFERENCES_PAGE = 'insights_preferences_page';
export const INSIGHTS_POT_VERSION_SWITCHER = 'insights_pot_version_switcher';
export const INSIGHTS_GAINERS_ACTIVE_STREAMS_SLIDER =
    'insights_gainers_active_streams_threshold_slider';
export const INSIGHTS_NMF = 'insights_nmf';
export const INSIGHTS_ARTIST_CHARTS = 'insights_artist_charts_tab';
export const INSIGHTS_ARTIST_PLAYLISTS = 'insights_artist_playlists_tab';
export const INSIGHTS_PRODUCT_SEARCH_ES = 'insights_product_search_es';
export const INSIGHTS_PRODUCT_MULTI_SEARCH = 'insights_product_multi_search';
export const INSIGHTS_STORE_FRONT_FIX = 'insights_store_front_fix';
export const INSIGHTS_STORE_FILTER_HIDDEN_APPLY_BUTTON =
    'insights_store_filter_hidden_apply_button';
export const INSIGHTS_ARTIST_PAGE_ENHANCEMENT =
    'insights_artist_page_enhancement';
export const INSIGHTS_ERROR_PAGE = 'insights_error_page_revamp';
export const INSIGHTS_SEARCH_SPOTIFY_V3 =
    'insights_search_updates_v3_gsr_spotify_enhanced';
export const INSIGHTS_SONG_PAGE_DISABLED_TOP_POSITION =
    'insights_song_page_disabled_top_position';
