import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { formatMessage } from '@theorchard/suite-frontend';
import { EMPTY_CHAR } from 'src/constants';

export const CLASSNAME = 'NmfParametersCell';
const CLASSNAME_LINE = `${CLASSNAME}-line`;
const CLASSNAME_LABEL = `${CLASSNAME}-label`;
const CLASSNAME_VALUE = `${CLASSNAME}-value`;

GridTable.defineColumnTemplate('nmfParameters', {
    align: 'left',
    Cell: ({ data }) => {
        return (
            <div className={CLASSNAME}>
                {Object.entries(data.parameters).map(([param, value]) => {
                    if (param === 'top10Rank' && value > 10) return null;

                    return (
                        <div key={param} className={CLASSNAME_LINE}>
                            <span className={CLASSNAME_LABEL}>
                                {formatMessage(`newReleases.${param}`)}
                            </span>
                            <span className={CLASSNAME_VALUE}>
                                {value ?? EMPTY_CHAR}
                            </span>
                        </div>
                    );
                })}
            </div>
        );
    },
});
