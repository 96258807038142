import React from 'react';
import { GridTable } from '@theorchard/suite-components';
import { GlyphIcon } from '@theorchard/suite-icons';
import { EMPTY_CHAR } from 'src/constants';

GridTable.defineColumnTemplate('change', {
    align: 'right',
    sortable: true,
    Cell: ({ value }) => {
        const getContent = () => {
            if (value === null)
                return (
                    <div className="newEntry">
                        <GlyphIcon name="dot" size={12} />
                    </div>
                );
            if (value === undefined || typeof value === 'string')
                return EMPTY_CHAR;

            if (value === 0)
                return (
                    <div className="noChange">
                        <div className="Glyph" /> 0
                    </div>
                );

            if (value > 0)
                return (
                    <span className="moveUp">
                        <GlyphIcon name="arrowUp" size={12} />
                        {value}
                    </span>
                );

            const absValue = Math.abs(value);
            return (
                <span className="moveDown">
                    <GlyphIcon name="arrowDown" size={12} />
                    {absValue}
                </span>
            );
        };
        return <div className="ChangeCell">{getContent()}</div>;
    },
});
