import React, { FC } from 'react';
import { formatMessage } from '@theorchard/suite-frontend';
import { GlyphIcon } from '@theorchard/suite-icons';
import cx from 'classnames';

const CLASSNAME = 'ContentPanel';

export const EMPTY_MESSAGE = 'error.noData.message';
export const TEST_ID = CLASSNAME;

export interface Props {
    className?: string;
    isEmpty?: boolean;
    message?: string | React.ReactNode;
    hideEmptyIcon?: boolean;
}

const ContentPanel: FC<Props> = ({
    className,
    isEmpty,
    message,
    children,
    hideEmptyIcon = false,
}) => (
    <div
        className={cx(CLASSNAME, className, {
            [`${CLASSNAME}-empty`]: isEmpty,
        })}
        data-testid={className}
    >
        {!isEmpty ? (
            children
        ) : (
            <div className={`${CLASSNAME}-empty-content`}>
                {!hideEmptyIcon && <GlyphIcon name="analytics" size={16} />}
                <div className={`${CLASSNAME}-empty-message`}>
                    {message || formatMessage(EMPTY_MESSAGE)}
                </div>
            </div>
        )}
    </div>
);

export default ContentPanel;
