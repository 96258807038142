import { useMemo } from 'react';
import { useQuery } from '@apollo/client';
import {
    PlaylistPlacementPositionTimeseries,
    PlaylistPlacementPositionTimeseriesVariables,
} from 'src/apollo/definitions/PlaylistPlacementPositionTimeseries';
import { selectPlaylistPlacementPositionTimeseries } from 'src/apollo/selectors/playlist';
import PlaylistPlacementPositionTimeseriesQuery from './playlistPlacementPositionTimeseries.gql';

export const usePlaylistPlacementPositionTimeseries = (
    variables: PlaylistPlacementPositionTimeseriesVariables,
    peakPosition: number | null,
    skipPositions?: boolean
) => {
    const skip = skipPositions || !variables.playlistId;
    const {
        loading,
        data: timeSeriesData,
        error,
    } = useQuery<
        PlaylistPlacementPositionTimeseries,
        PlaylistPlacementPositionTimeseriesVariables
    >(PlaylistPlacementPositionTimeseriesQuery, { variables, skip });

    const data = useMemo(
        () =>
            selectPlaylistPlacementPositionTimeseries(
                timeSeriesData?.playlistPlacementPositionTimeseries?.items,
                peakPosition
            ),
        [timeSeriesData, peakPosition]
    );

    return { loading, data, error };
};
