import React, { FC } from 'react';
import { GridTable, GridTableCellProps } from '@theorchard/suite-components';
import { PlaylistType } from 'src/apollo/selectors/playlist';
import PlatformIcon from 'src/components/platformIcon';
import PlaylistLink from 'src/components/playlistLink';
import TruncatedText from 'src/components/truncatedText';
import { STORES_BY_ID } from 'src/constants/stores';
import { getPlaylistDescription } from '../utils';

const PlaylistLinkCell: FC<GridTableCellProps<PlaylistType>> = ({
    data: { curator, playlistType, storeId, playlistUri, playlistName },
}) => {
    const description = getPlaylistDescription(curator, playlistType.name);
    const platform = storeId ? STORES_BY_ID[storeId] : null;

    return (
        <div className="PlaylistLinkCell">
            <PlatformIcon storeId={storeId} platform={platform} />
            <div className="PlaylistLinkCell-name-container">
                <PlaylistLink playlistUrl={playlistUri} title={playlistName} />
                <div className="PlaylistLinkCell-description">
                    {description && (
                        <TruncatedText
                            className="PlaylistLinkCell-description-text"
                            disableTooltipPointerEvents
                            value={description}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

GridTable.defineColumnTemplate('playlistLink', {
    align: 'left',
    Cell: PlaylistLinkCell,
});

export default PlaylistLinkCell;
